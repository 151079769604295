import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextInputWithEmoji from "../components/textInputWithEmoji.js"
import { HashLoader } from "react-spinners"
const backend = require("../helpers/devsurvival.api.js")

const BlogPostEditFormTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const [notification, setNotification] = useState("")
  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({
    postTitle: post.frontmatter.title,
    name: "",
    email: "",
    suggestion: "",
  })
  //const { previous, next } = pageContext
  const submit = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      await backend.createEdit(formState)
      setNotification("Edit suggestion has been forward to author!")
      setTimeout(() => setLoading(false), 500)
    } catch (err) {
      setNotification("Sorry, server error. Please try again later")
      setTimeout(() => setLoading(false), 500)
    }
  }

  const onChange = e =>
    setFormState({ ...formState, [e.target.name]: e.target.value })
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <h4>📝 Suggest Edit - {post.frontmatter.title}</h4>
      <Link to={`${location.pathname.replace("/edit", "/")}`}>
        Go Back To Post
      </Link>
      {loading ? (
        <HashLoader />
      ) : (
        <form onSubmit={submit} className="flex-column-start">
          {notification.length ? <p>{notification}</p> : ""}
          <input
            type="text"
            required
            placeholder="name*"
            name="name"
            value={formState.name}
            onChange={onChange}
          />
          <input
            type="email"
            required
            placeholder="email*"
            name="email"
            value={formState.email}
            onChange={onChange}
          />
          <TextInputWithEmoji
            required
            placeholder="suggestion* . Note: you can also use emoji tags here ( e.g. #smile will generate 😀)"
            name="suggestion"
            value={formState.suggestion}
            onChange={onChange}
          />
          <button>Submit</button>
        </form>
      )}
    </Layout>
  )
}

export default BlogPostEditFormTemplate

export const pageQuery = graphql`
  query BlogPostEditBySlug($slugEdit: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slugEdit
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
    markdownRemark(fields: { slugEdit: { eq: $slugEdit } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        author
      }
    }
  }
`
